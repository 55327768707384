import React, { useState } from "react";
import "./styles.css";
import odinLogo from '../../assets/images/Odin Education Logo_icon.png'

const FloatingButton = () => {
  let location = window.location.pathname;
  const [hovered, setHovered] = useState(false);
  return (
    <div>
      {location === "/contact" ? null : (
        <>
          {location === "/our-journey" ? (
            <div className="floating-contact">
              <a href="/contact" className="btn contact animate-charcter">
                Contact Us
              </a>
            </div>
          ) : (
            <div className="floating-contact">
              <a href={location === '/bbbee-investments' ? "https://calendly.com/requestacallback/15min?month=2023-03" : "/contact"} target={location === '/bbbee-investments' ? "_blank" : null} className="btn btn-primary animate-charcter">
                Contact Us
              </a>
            </div>
          )}
        </>
      )}
      <div onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
         className={hovered ? "floating-beta hoveredImage" :"floating-beta"}>
        <a href={"https://odinbot.cubettech.in"}>
          <img className="zoom" src={odinLogo} width={130} height={130}/>
          <p style={{ position: "absolute", top: "53px", right: "27px", color: "white" }}>ODIN BOT</p>
        </a>
      </div>
    </div>
  );
};

export default FloatingButton;
