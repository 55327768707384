import { useState } from "react";
import apis from "../apis";

const useListing = (path) => {
  const [loader, setLoader] = useState(false);
  const [list, setList] = useState([]);
  const getList = async (dirPath = "", params = {}) => {
    try {
      setLoader(true);
      const res = await apis.get(dirPath ? dirPath : path, { params });
      setList(res?.data?.data || []);
      setLoader(false);
    } catch (err) {
      setLoader(false);
    }
  };
  return {
    loader,
    list,
    getList,
  };
};

export default useListing;
