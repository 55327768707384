import React, { createContext, useEffect, useState } from "react";
import useListing from "../apis/services/useListing";
const LoaderContext = createContext();

const LoaderProvider = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [count,setCount] = useState(0);
  const [isRedirectTool,setIsRedirectTool] = useState(false)
  const { list: points, getList } = useListing("sector");

  useEffect(() => {
    getList();
  }, []);

  const setLoader = (status) => setLoading(status);
  const setProgessCount = (count) => setCount(count);
  const setRedirectToTool = (redirect) => setIsRedirectTool(redirect);
  return (
    <LoaderContext.Provider value={{ loading, points,count,isRedirectTool, setLoader,setProgessCount,setRedirectToTool }}>
      {children}
    </LoaderContext.Provider>
  );
};

// const withLoader = (Child) => (props) =>
//   (
//     <LoaderProvider.Consumer>
//       {(context) => <Child {...props} {...context} />}
//     </LoaderProvider.Consumer>
//   );

export { LoaderProvider, LoaderContext /*withLoader*/ };
