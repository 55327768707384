import { useContext } from "react";
import { LoaderContext } from "../../contexts/loaderContext";

const useLoader = () => {
  const { points, loading, setLoader,setProgessCount,count,setRedirectToTool,isRedirectTool } = useContext(LoaderContext);
  const setLoading = (status) =>  setLoader(status);
  const setCount = (count) => setProgessCount(count);
  const setRedirectTool = (redirect) =>  setRedirectToTool(redirect)
  return { loading, points,count,isRedirectTool, setLoading,setCount,setRedirectTool };
};

export default useLoader;
