const steps = [
  "step-1",
  "step-2",
  "step-3",
  "step-4",
  "step-5",
  "step-6"
];
const emailSteps = [
  "step-1",
  "step-2",
  "step-3",
  "step-4",
  "step-5",
  "step-6",
  "step-7"
];

const scoreCardFields = [
  "csize",
  "csector",
  "cfinancialyear",
  "npat",
  "continue",
];
const regLastDigit = ['06','07','08','09','10','12','21','22','23','24','25','26','30','31']

export { steps, scoreCardFields,regLastDigit,emailSteps };
