// import Pages from "./routes";
import Loader from "./components/Loader";

import { lazy, Suspense } from "react";
import { LoaderProvider } from "./contexts/loaderContext";
import { ThankyouProvider } from "./contexts/thankyouContext";
// import "./assets/css/bootstrap.css";
import FloatingButton from "./layouts/FloatingButton";
import { useLocation } from "react-router-dom";
import NewLandingHeader from "./layouts/Header/NewLandingHeader";

const Header = lazy(() => import("./layouts/Header"));
const Footer = lazy(() => import("./layouts/Footer"));
const Pages = lazy(() => import("./routes"));
const Content = lazy(() => import("./components/PageSettings"));
function App() {
  const location = window.location.pathname;
  return (
    <LoaderProvider>
       {/* <Suspense fallback={<Loader init={true} />}> */}
        {location.indexOf('-preview') > -1 ? '' : (location !== '/bbbee-investments') && (location !== '/bbbee-calculator') ? <Header /> : <NewLandingHeader/>}
        <FloatingButton/>
        <ThankyouProvider>
          <Content>
            <Pages />
          </Content>
        </ThankyouProvider>
        {location.indexOf('-preview') > -1 ? '' :<Footer />}
        
        <Loader />
       {/* </Suspense> */}
    </LoaderProvider>
  );
}

export default App;
