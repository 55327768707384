import { useRef, useState } from "react";
import { Button, FloatingLabel, Form, Modal } from "react-bootstrap";
import ReactCrop, {
  centerCrop,
  makeAspectCrop,
  Crop,
  PixelCrop,
} from "react-image-crop";
import { canvasPreview } from "./canvasPreview";
import { useDebounceEffect } from "./useDebounceEffect";
import Tesseract from "tesseract.js";
import "react-image-crop/dist/ReactCrop.css";

const CropToolModal = ({
  openCropModal,
  setOpenCropModal,
  imgSrc,
  submitImage,
  imageName,
  imgToCrop,
  fileError,
  crop,
  setCrop,
  setScale,
  scale,
}) => {
  const [validated, setValidated] = useState(false);
  const imgRef = useRef(null);
  const { createWorker } = Tesseract;
  const previewCanvasRef = useRef(null);
  //   {
  //   unit: "%",
  //   width: 30,
  //   height: 50,
  //   x: 25,
  //   y: 25,
  // }
  // );
  const [completedCrop, setCompletedCrop] = useState();
  //   {
  //   unit: "%",
  //   width: 30,
  //   height: 50,
  //   x: 25,
  //   y: 25,
  // }
  // );

  async function doOCR(imageBuffer) {
    const worker = await createWorker();
    // await worker.loadLanguage("por");
    // await worker.initialize("por");
    // await worker.loadLanguage("eng");
    // await worker.initialize("eng");

    const data = await worker.recognize(imageBuffer);

    await worker.terminate();
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    if (previewCanvasRef.current) {
      previewCanvasRef.current?.toBlob(
        (blob) => {
          let file = new File([blob], imageName, { type: "image/jpeg" });
          submitImage(file);
          // const previewUrl = window.URL.createObjectURL(blob);
          // const anchor = document.createElement("a");
          // anchor.download = "cropPreview.png";
          // anchor.href = URL.createObjectURL(blob);
          // window.URL.revokeObjectURL(previewUrl);
        },
        "image/png",
        1
      );
    } else {
      submitImage(imgToCrop);
    }
    // if (form.checkValidity()) onNextClick(true);
    setValidated(true);
  };

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale
        );
        doOCR(previewCanvasRef.current.toDataURL());
      }
    },
    100,
    [completedCrop, scale, imgSrc, crop]
  );
  function centerAspectCrop(mediaWidth, mediaHeight, aspect, naturalWidth) {
    const imgWidth =  40;
    const imgHeight = 70;
    return centerCrop(
      makeAspectCrop(
        {
          unit: "%",
          width: imgWidth,
          height: imgHeight,
        },
        aspect,
        mediaWidth,
        mediaHeight
      ),
      mediaWidth,
      mediaHeight
    );
  }

  function onImageLoad(e) {
    // if (aspect) {
    const { width, height, naturalWidth } = e.currentTarget;
    setCrop(centerAspectCrop(width, height, 16 / 9, naturalWidth));
    // }
  }

  return (
    <>
      <Modal
        show={openCropModal}
        onHide={setOpenCropModal}
        className="details-modal"
      >
        <Modal.Header
          closeButton
          closeLabel="Close"
          // closeVariant="White"
          bsPrefix="modal-header"
        >
          <Modal.Title>Crop Image</Modal.Title>
          {/* <h5 className="m-3">Your Details</h5> */}
        </Modal.Header>
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Modal.Body>
            <div>
              {!!imgSrc && (
                <div>
                  <ReactCrop
                    style={{ width: "100%", height: "auto" }}
                    crop={crop}
                    onChange={(_, percentCrop) => setCrop(percentCrop)}
                    onComplete={(c) => setCompletedCrop(c)}
                    aspect={1.7777777777777777}
                    maxWidth={350}
                    maxHeight={280}
                    locked={true}
                  >
                    <img
                      ref={imgRef}
                      alt="Crop me"
                      src={imgSrc}
                      style={{
                        transform: `scale(${scale})`,
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                      onLoad={onImageLoad}
                    />
                  </ReactCrop>
                </div>
              )}
              <div>
                <Form.Label>Scale</Form.Label>
                <Form.Range
                  min={0}
                  max={2}
                  step={0.1}
                  value={scale}
                  onChange={(e) => setScale(Number(e.target.value))}
                />
              </div>
              <div>
                {!!completedCrop && (
                  <canvas
                    ref={previewCanvasRef}
                    style={{
                      border: "1px solid black",
                      objectFit: "contain",
                      width: completedCrop.width,
                      height: completedCrop.height,
                      display: "none",
                    }}
                  />
                )}
              </div>
              <div>
                {!!completedCrop && (
                  <>
                    <canvas
                      ref={previewCanvasRef}
                      style={{
                        border: "1px solid black",
                        objectFit: "contain",
                        width: completedCrop.width,
                        height: completedCrop.height,
                      }}
                    />
                  </>
                )}
              </div>
            </div>
            {fileError ? (
              <div className="text-danger py-2">{fileError}</div>
            ) : (
              ""
            )}
          </Modal.Body>
          <Modal.Footer>
            <div className="d-flex justify-content-end">
              <Button
                className={`text-primary border-primary `}
                variant="light"
                type="submit"
                id="user"
              >
                Crop
              </Button>
            </div>
            {/* <Button onClick={onClearPoints}>OK</Button> */}
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default CropToolModal;
