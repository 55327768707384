import React, { createContext, useState } from "react";
const ThankyouContext = createContext();

const ThankyouProvider = ({ children }) => {
  const [isThankyou, setIsThankyou] = useState(false);

  return (
    <ThankyouContext.Provider value={{ isThankyou, setIsThankyou }}>
      {children}
    </ThankyouContext.Provider>
  );
};
export { ThankyouProvider, ThankyouContext /*withThankyou*/ };
