import logo from "../../assets/images/newLanding/Odin-Education-Logo.svg";
import mail from "../../assets/images/newLanding/mail.svg";
import phone from "../../assets/images/newLanding/phone.svg";
import { Container, Navbar } from "react-bootstrap";
import { useState } from "react";
import RegisterModal from "../../components/BBBEE/RegisterModal";

const NewLandingHeader = () => {

  const [registerModal,setRegisterModal] = useState(false)

  const redirectToSection = (e) => {
    let elem = document.getElementById(e.target.name);
    elem.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "start",
    });
    let element = document.getElementById(e.target.name);
    let headerOffset = 40;
    let elementPosition = element.getBoundingClientRect().top;
    let offsetPosition = elementPosition + window.pageYOffset - headerOffset;

    window.scrollTo({
      top: offsetPosition,
      block: "start",
      behavior: "smooth",
    });
  };

  const handleParnterRegister = () => {
    setRegisterModal(true)
  }

  return (
    <>
      <header className="w-100 header-new fixed-top ">
        {/* <div className="container"> */}
        <Container>
          <Navbar
            className="navbar navbar-expand-lg navbar-light p-0"
            expand="lg"
          >
            <a className="navbar-brand" href="/">
              <img className="nav-logo" src={logo} alt="logo" width="150" />
            </a>
            <Navbar.Toggle
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </Navbar.Toggle>
            <Navbar.Collapse
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav me-auto mb-2 mb-lg-0 justify-content-end">
                {/* <li className="nav-item">
                  <a
                    className="bbbee-content-button btn btn-primary"
                    href="/ai-event"
                    // target="_blank"
                    // href="https://docs.google.com/forms/d/e/1FAIpQLSfBVXflTEH0bFOMn8N4kjaMVB-SVpYxvXFKkM5yEExRDFMMcg/viewform"
                  >
                    Register for AI Corporate Event
                  </a>
                </li> */}
                {/* <li className="nav-item">
                  <a
                    className="nav-link cursor"
                    name="digitalLearning"
                    onClick={redirectToSection}
                  >
                    About ODIN
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#csiInvestment">
                    TRACK YOUR INVESTMENT
                  </a>
                </li>*/}
                <li className="nav-item">
                  <a
                    className="nav-link cursor"
                    name="revolutionisingEducation"
                    onClick={handleParnterRegister}
                  >
                    Become a Consultant Partner
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link cursor"
                    name="investorSays"
                    onClick={redirectToSection}
                  >
                    INVESTOR REVIEWS
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link cursor"
                    name="BBBEEToolSection"
                    onClick={redirectToSection}
                  >
                    B-BBEE Pack Generator
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link cursor"
                    name="faq"
                    onClick={redirectToSection}
                  >
                    FAQ
                  </a>
                </li>
                {/* <li className="nav-item">
							<a className="nav-link" href="/contact" target='_blank'>
								<img src={phone} alt="phone"/>
							</a>
						</li> */}
                <li className="nav-item">
                  <a
                    className="nav-link"
                    href="https://calendly.com/requestacallback/15min?month=2023-03"
                    target="_blank"
                  >
                    <img src={mail} alt="mail" />
                  </a>
                </li>
              </ul>
            </Navbar.Collapse>
          </Navbar>
        </Container>
      </header>
      <RegisterModal
      showRegisterModal={registerModal}
      hideRegisterModal={setRegisterModal}/>
    </>
  );
};

export default NewLandingHeader;
