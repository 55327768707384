import { useRef, useState } from "react";
import { Alert, Button, FloatingLabel, Form, Modal } from "react-bootstrap";
import apis from "../../apis/apis";
import { regLastDigit } from "../../services/constants/bbbeeSteps";
import CropToolModal from "./crop/CropToolModal";

const RegisterModal = ({ showRegisterModal, hideRegisterModal }) => {
  const [phnError, setPhnError] = useState("");
  const [regValue, setRegValue] = useState("");
  const [vatValue, setVatValue] = useState("");
  const [regValidationError, setRegValidationError] = useState("");
  const [vatValidationError, setVatValidationError] = useState("");
  const [validated, setValidated] = useState(false);
  const [values, setValues] = useState({});
  const [imgSrc, setImgSrc] = useState("");
  const [imgToCrop, setImgToCrop] = useState(undefined);
  const [imageName, setImageName] = useState("");
  const [openCropModal, setOpenCropModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [registerSaveError, setRegisterSaveError] = useState("");
  const [fileError, setFileError] = useState("");
  const [file, setFile] = useState(undefined);
  const [fileName, setFileName] = useState("");
  const [fileTypeError,setFileTypeError] = useState('')
  const [consultantId, setConsultantId] = useState("");
  const [thankYouModal,setThankYouModal] = useState(false)
  const hiddenFileInput = useRef(null);
  const [crop, setCrop] = useState();
  const [scale, setScale] = useState(1);


  const checkVatValidation = (e, subKey) => {
    let value = e.target.value;
    if (value) {
      if (value.startsWith("4") && value.length === 10) {
        // setCompanyData({ ...companyData, [subKey]: value });
        setVatValidationError("");
      } else {
        setVatValidationError("Please fill the correct Vat Number");
      }
    } else {
      setVatValidationError("");
    }
  };

  const checkValidation = (e, subKey) => {
    let value = e.target.value.split("/").join("");
    if (value) {
      if (
        (value.startsWith("19") || value.startsWith("20")) &&
        value.length === 12 &&
        regLastDigit.some((val) => value.endsWith(val))
      ) {
        // setCompanyData({ ...companyData, [subKey]: e.target?.value });
        setRegValidationError("");
      } else {
        setRegValidationError(
          "Please fill the correct company Registration Number"
        );
      }
    } else {
      setRegValidationError("");
    }
  };
  function addItemEvery(str, item, every) {
    let slashLength = every;
    for (let i = 0; i < str.length; i++) {
      slashLength = i > 5 ? 11 : slashLength;
      if (!(i % (slashLength + 1))) {
        str = str.substring(0, i) + item + str.substring(i);
      }
    }

    return str.substring(1);
  }
  const onClose = () => {
    setValues({});
    hideRegisterModal();
    setRegValue("");
    setVatValue("");
    setFileError("");
  };
  const handleImageChange = (event) => {
    setFileTypeError('')
    const fileUploaded = event.target.files[0];
    setFileError("");
   if(!fileUploaded?.type.includes('image')){
    setFileTypeError('Please upload an Image')

   } else{
    if (fileUploaded?.size > 1000000) {
      setFileError("File size is Greater than 1 MB");
    } else {
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setImgSrc(reader.result?.toString() || "");
        setImgToCrop(event.target.files[0]);
        // if (reader.result) doOCR(reader.result);
      });
      reader.readAsDataURL(event.target.files[0]);
      // uploadFile(fileUploaded);
      setImageName(event.target.files[0]?.name);
      setOpenCropModal(true);
    }
   } 
  };
  const closeCropModal = () => {
    setOpenCropModal(false)
    setImgSrc(undefined)
    setImageName(undefined)
    setCrop(undefined)
  }

  const onValueChange = (e, subKey) => {
    let phnValue;
    if (subKey === "regNo") {
      // Format the value and remove slashes, so addItemEvery will work
      let value = e.target.value.replace(/\/+/g, "");
      // We substring to add / to only the first part, every two characters
      const firstFourChars = addItemEvery(value.substring(0, 12), "/", 4);
      value = firstFourChars + value.substring(12, value.length);
      setRegValue(value);
      setValues({ ...values, [subKey]: value });

      setRegValidationError("");
    } else if (subKey === "vatNo") {
      setVatValue(e.target.value);
      setValues({ ...values, [subKey]: e.target.value });
    } else if (subKey === "phNo") {
      let value = e.target.value;
      let regex = /^0[0-9]/;
      let secondRegex = /^27[0-9]/;
      let isvalid = 0;
      // setPhnError("");
      // setYourDetailsData({
      //   ...yourDetailsData,
      //   [subKey]: e.target.value,
      // });
      if (regex.test(value) || secondRegex.test(value)) {
        if (value.charAt(0) === "0") {
          phnValue = `+27${value.slice(1)}`;
        } else if (value.substring(0, 2) === "27") {
          phnValue = `+27${value.slice(2)}`;
        }
        setPhnError("");
        isvalid = 1;
        setValues({ ...values, [subKey]: phnValue });

        // setFormdata({
        //   [itemKey]: { ...item, [subKey]: phnValue },
        // });
      } else {
        if (
          e.target.value.startsWith("+27") &&
          e.target?.value?.length === 12
        ) {
          setPhnError("");
          isvalid = 1;
          setValues({
            ...values,
            [subKey]: e.target.value,
          });

          // setFormdata({
          //   [itemKey]: { ...item, [subKey]: e.target.value },
          // });
        } else {
          setPhnError("Phone  number is not valid");
          setValues({
            ...values,
            [subKey]: e.target.value,
          });

          // setFormdata({
          //   [itemKey]: { ...item, [subKey]: e.target.value },
          // });
        }
      }
      if (isvalid === 0 && e.target.value?.length !== 12) {
        setPhnError("Phone number is not valid");
      }
    } else if (subKey === "rebateInterested") {
      setValues({ ...values, [subKey]: e.target.checked });
    } else {
      setValues({ ...values, [subKey]: e.target.value });
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    if (form?.checkValidity()) {
      const data = {
        name: values?.name,
        email: values?.email,
        phNo: values?.phNo,
        regNo: values?.regNo,
        companyName: values?.companyName,
        vatNo: values?.vatNo,
        consultantLogo: file,
        rebateInterested: values?.rebateInterested,
      };
      saveRegister(data);
      // setconfirmEmailValidation(false);
      // setDetailsModalLoading(true);
      // // if (yourDetailsData?.email === yourDetailsData?.confirmEmail) {
      // handleYourDetailsSubmit(companyData, yourDetailsData);
    } else {
      setValidated(true);
    }
  };
  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  const saveRegister = async (values) => {
    setRegisterSaveError("");
    try {
      const submitResponse = await apis.post(`score/register`, { ...values });
      hideRegisterModal(false);
      setConsultantId(submitResponse?.data?.data);
      setThankYouModal(true)
      // setBBBEEConsultant(true);
      // setIsCheckboxDisabled(true);
      // setRegisterModal(false);
      // setIsRegistered(true);
      // setFormdata({
      //   ...item,
      //   consultant: { ...submitResponse?.data?.data, consultantLogo: file },
      // });
    } catch (err) {
      setRegisterSaveError(err?.response?.data?.error);
      // setDetailsModalLoading(false);
    }
  };
  const uploadFile = async (file) => {
    const fileData = new FormData();
    fileData.append("file", file, file?.name);
    setFileError("");
    setLoading(true);
    try {
      const fileResponse = await apis.post("score/upload", fileData);
      setFile(fileResponse.data?.processedImage);
      setFileName(file?.name);
      setLoading(false);
      setOpenCropModal(false);
      setFileError("");
      // setFormdata({
      //   ...item,
      //   ["consultantLogo"]: fileResponse.data?.processedImage,
      //   ["isConsultant"]: `${bBBEEConsultant}`,
      // });
    } catch (err) {
      setFileError(err?.response?.data?.error);
      setFile(undefined);
      setFileName(undefined);
      setLoading(false);
      setScale(1)
    }
  };

  return (
    <>
      {consultantId?.uniqueNo ? (
        <>
          <Modal centered show={thankYouModal}>
            <Modal.Body>
              <Alert variant="success">
                <Alert.Heading>Thank you for Registering with us.</Alert.Heading>
                {/* <p>
                  Thank you for Registering with us.
                </p> */}
                <p>Your Consultant Id <span className="fw-bolder">{consultantId?.uniqueNo}</span></p>
                <hr />
                <p className="mb-0">Talk to you soon</p>
                <p>Odin Education</p>
              </Alert>
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={() => setThankYouModal(false)}>Continue</Button>
            </Modal.Footer>
          </Modal>
        </>
      ) : 
        <Modal
          show={showRegisterModal}
          onHide={onClose}
          closeButton
          className="details-modal"
        >
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Modal.Header
              closeButton
              closeLabel="Close"
              closeVariant="White"
              bsPrefix="modal-header"
            >
              <Modal.Title className="text-primary w-100 ps-3">
                Consultant Personal Details
              </Modal.Title>
              {/* <h5 className="m-3">Your Details</h5> */}
            </Modal.Header>
            <Modal.Body>
              <>
                <div className="py-2">
                  <FloatingLabel
                    controlId="validationName"
                    label={
                      <span style={{ display: "flex" }}>
                        Full Name and Surname <p style={{ color: "red" }}> *</p>
                      </span>
                    }
                    className="text-primary fs-6 mb-3"
                  >
                    <Form.Control
                      required
                      type="text"
                      placeholder="Your Name"
                      className="form-floating"
                      name="name"
                      // onBlur={(e) => checkValidation(e, "name")}
                      onChange={(e) => onValueChange(e, "name")}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please fill your name.
                    </Form.Control.Feedback>
                  </FloatingLabel>
                  <FloatingLabel
                    controlId="validationContact"
                    label={
                      <span style={{ display: "flex" }}>
                        Enter Cell Number <p style={{ color: "red" }}> *</p>
                      </span>
                    }
                    className="text-primary fs-6 mb-3"
                  >
                    <Form.Control
                      required
                      type="text"
                      placeholder="Enter your Cell Number"
                      className="form-floating"
                      name="phNo"
                      maxLength={"12"}
                      // value={yourDetailsData?.phNo}
                      value={values?.phNo}
                      onChange={(e) => onValueChange(e, "phNo")}
                    />
                    {phnError ? (
                      <div className="text-danger">{phnError}</div>
                    ) : (
                      ""
                    )}
                    <Form.Control.Feedback type="invalid">
                      Please fill your Cell number
                    </Form.Control.Feedback>
                  </FloatingLabel>
                  <FloatingLabel
                    controlId="validationEmail"
                    label={
                      <span style={{ display: "flex" }}>
                        Enter your Email <p style={{ color: "red" }}> *</p>
                      </span>
                    }
                    className="text-primary fs-6 mb-3"
                  >
                    <Form.Control
                      required
                      type="email"
                      placeholder="Enter your Email"
                      className="form-floating"
                      name="email"
                      // value={yourDetailsData?.email}
                      // value={item?.email}
                      pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                      onChange={(e) => onValueChange(e, "email")}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please fill your valid email.
                    </Form.Control.Feedback>
                  </FloatingLabel>

                  {/* <Form.Control
              type="file"
              accept=".jpeg"
              onChange={(e) => {
                uploadFile(e.target.files[0]);
              }}
              // value={file}
            /> */}
                  <Modal.Title className="text-primary w-100 pe-3">
                    Consultant Company Details
                  </Modal.Title>

                  <FloatingLabel
                    controlId="validationName"
                    label={
                      <span style={{ display: "flex" }}>
                        Enter Registered Name <p style={{ color: "red" }}> *</p>
                      </span>
                    }
                    className="text-primary fs-6 mb-3"
                  >
                    <Form.Control
                      required
                      type="text"
                      placeholder="Your Company Name"
                      className="form-floating"
                      name="companyName"
                      onChange={(e) => onValueChange(e, "companyName")}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please fill your company name.
                    </Form.Control.Feedback>
                  </FloatingLabel>
                  <FloatingLabel
                    controlId="CompanyRegNo"
                    label={
                      <span style={{ display: "flex" }}>
                        Enter Registration Number
                      </span>
                    }
                    className="text-primary fs-6 mb-3 floating-label-mob"
                  >
                    <Form.Control
                      type="text"
                      name="regNo"
                      value={regValue}
                      placeholder="Registration Number"
                      className="form-floating"
                      onChange={(e) => onValueChange(e, "regNo")}
                      onBlur={(e) => checkValidation(e, "regNo")}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please fill company Registration Number.
                    </Form.Control.Feedback>
                    <div style={{ color: "red" }}>{regValidationError}</div>
                  </FloatingLabel>
                  <FloatingLabel
                    controlId="CompanyVat"
                    label={
                      <span style={{ display: "flex" }}>Enter Vat No</span>
                    }
                    className="text-primary fs-6 mb-3 floating-label-mob"
                  >
                    <Form.Control
                      type="number"
                      name="vatNo"
                      value={vatValue}
                      // max={10}
                      placeholder="Vat Number"
                      className="form-floating"
                      onChange={(e) => onValueChange(e, "vatNo")}
                      onBlur={(e) => checkVatValidation(e, "vatNo")}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please fill company Vat Number.
                    </Form.Control.Feedback>
                    <div style={{ color: "red" }}>{vatValidationError}</div>
                  </FloatingLabel>
                  <Button
                    className="button-upload"
                    disabled={loading}
                    onClick={handleClick}
                    title="This allows us to include your logo on the documentation."
                  >
                    {loading ? "Uploading..." : "Upload Company logo"}
                  </Button>
                  <input
                    type="file"
                    onChange={handleImageChange}
                    accept=".jpeg,.jpg,.png"
                    ref={hiddenFileInput}
                    style={{ display: "none" }} // Make the file input element invisible
                  />
                  {fileName ? (
                    <span className="px-2">{fileName}</span>
                  ) : (
                    <span className="px-2 font-10">
                      (Image width 150px -500px, image type - jpg,jpeg,png)
                    </span>
                  )}
                  <div className="disclaimer-content pt-1">
                    {/* *File size should be less than 1 mb and image width should be
                between 150 px * 500px */}
                    We will place your company logo on the cover of all Audit
                    Packs generated for your clients.
                  </div>
                  <div>
                    <Form.Check
                      className="m-1"
                      title="Are you interested in Odin Rebate Program?"
                      label="Are you interested in Odin Rebate Program?"
                      onChange={(e) => onValueChange(e, "rebateInterested")}
                    ></Form.Check>
                  </div>
                  {/* <div className="disclaimer-content pt-1">
                For image resizing we recommend to use{" "}
                <a href="https://imageresizer.com/" target="_blank">
                  image resizer
                </a>
              </div> */}
                  {/* {fileError ? (
                <div className="text-danger py-2">{fileError}</div>
              ) : (
                ""
              )} */}
                </div>
                {registerSaveError ? (
                  <div className="text-danger">{registerSaveError}</div>
                ) : (
                  ""
                )}
              </>
              
            </Modal.Body>
            <Modal.Footer>
              <Button id="register" type="submit" disabled={!file}>
                Submit
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      }
      <CropToolModal
        openCropModal={openCropModal}
        setOpenCropModal={closeCropModal}
        imgSrc={imgSrc}
        imageName={imageName}
        submitImage={uploadFile}
        imgToCrop={imgToCrop}
        fileError={fileError}
        crop={crop}
        setCrop={setCrop}
        scale={scale}
        setScale={setScale}
      />
    </>
  );
};

export default RegisterModal;
